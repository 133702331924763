import { Box, Link, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from 'store/store'
import colors from 'utils/colors'
import TestIDs from 'utils/TestIDs'

export interface FooterLink {
    label: string
    href?: string
}

const Footer = (): JSX.Element => {
    const { customize, user } = useSelector((state: AppState) => ({
        user: state.generalState.user,
        customize: state.generalState.customize,
    }))
    const { t } = useTranslation()
    const history = useHistory()

    const hiddenVersion = require('./hiddenVersion.json')

    return (
        <Box
            width={1}
            bgcolor={colors.main}
            alignItems={'center'}
            marginTop={'auto'}
            paddingTop={2}
            paddingBottom={2}
            display={'flex'}
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            borderTop={`5px solid ${colors.lightMain}`}
            {...TestIDs.GET_BASE('FOOTER')}
            position={'relative'}
        >
            <Box
                display={'flex'}
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                marginTop={0}
                marginLeft={12}
                flex={2}
                height={1}
            >
                <img
                    src={process.env.PUBLIC_URL + '/customize/footerLogo.png'}
                    alt={'footerLogo.png'}
                    style={{ maxWidth: 120, width: '100%', height: '100%', objectFit: 'cover', marginTop: -4 }}
                />
            </Box>
            {user && (
                <Box>
                    <Box paddingRight={{ xs: 1, sm: 2 }}>
                        <Typography
                            variant="subtitle2"
                            style={{
                                cursor: 'pointer',
                                color: colors.white,
                                textDecorationLine: 'underline',
                                marginLeft: 20,
                                marginRight: 20,
                            }}
                            onClick={() => history.push('/cancelContract')}
                        >
                            {t('cancelContract')}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Box
                flex={10}
                display={'flex'}
                alignItems={'center'}
                marginLeft={{ xs: 4, md: 40 }}
                textAlign={'center'}
                color={colors.white}
                flexWrap={'wrap'}
                height={1}
            >
                {customize &&
                    customize.footer.links.length > 0 &&
                    customize.footer.links.map((link: FooterLink, index: number): JSX.Element => {
                        return link.href ? (
                            <Box key={link.label + index} paddingRight={{ xs: 1, sm: 2 }}>
                                <Link target="_blank" href={link.href} color={'textSecondary'}>
                                    <Typography variant="subtitle2">{t(link.label)}</Typography>
                                </Link>
                            </Box>
                        ) : (
                            <Box key={link.label + index} paddingRight={{ xs: 1, sm: 2 }}>
                                <Typography variant="subtitle2">{t(link.label)}</Typography>
                            </Box>
                        )
                    })}
            </Box>
            <Box position={'absolute'} right={'0'} bottom={'0'}>
                <Typography color="primary" variant={'subtitle2'}>
                    Version:{hiddenVersion.version} Iteration:{hiddenVersion.iteration}
                </Typography>
            </Box>
        </Box>
    )
}

export default Footer
